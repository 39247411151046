import i18n from '@/i18n'

const { t } = i18n.global

export const contentTableConfig = {
  propList: [
    {
      prop: 'module',
      label: 'log.module',
      formatter: (row) => {
        return t(`menu.${row}`)
      },
      width: 180
    },
    {
      prop: 'resource',
      label: 'log.resource',
      width: 180
    },
    {
      prop: 'operationType',
      label: 'log.operation-type',
      width: 130
    },
    {
      prop: 'message',
      label: 'log.message'
    },
    {
      prop: 'result',
      label: 'Result',
      width: 120
    },
    {
      prop: 'createdBy',
      label: 'Operator',
      width: 120
    },
    {
      prop: 'ipAddress',
      label: 'log.ip-address',
      width: 120
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'general.operation-time',
      width: 160
    }
  ],
  showIndexColumn: true
  // showSelectColumn: true
}
